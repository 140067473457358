<template>
  <v-container class="general">
    <PageTitle :title="$t('group_menu.page_title')" />
    <v-layout row wrap>
      <v-flex xs12 class="mb-4">
        <div class="sw-h5">
          {{ $t("group_menu.name_label") }}
        </div>
        <v-text-field
          v-model="model.name"
          v-validate.disable="'required'"
          :error-messages="errors.collect('menu_name')"
          :data-vv-name="'menu_name'"
          :data-vv-as="$t('group_menu.name_data_vv_as')"
          :placeholder="$t('group_menu.name_placeholder')"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 class="mb-4">
        <div class="sw-h5">
          {{ $t("group_menu.slug_label") }}
        </div>
        <v-select
          v-model="model.slug"
          :items="menuTypes"
          item-text="name"
          item-value="slug"
          v-validate.disable="'required'"
          :data-vv-name="'menu-type'"
          :data-vv-as="$t('group_menu.slug_data_vv_as')"
          :error-messages="errors.collect('menu-type')"
          :placeholder="$t('group_menu.slug_placeholder')"
          :disabled="model && model.id ? true : false"
          persistent-hint
        ></v-select>
      </v-flex>
      <v-flex xs12 class="mb-4">
        <v-switch v-model="model.enabled">
          <template slot="label">
            <div class="black--text">
              {{ $t("group_menu.enabled_label") }}
            </div>
          </template>
        </v-switch>
      </v-flex>
      <v-flex xs12>
        <v-btn
          round
          large
          class="ml-0 mr-3 white sw-accent text-none"
          @click="
            $router.push({
              name: 'group_menu_list',
              params: { group_id: groupId },
            })
          "
          >{{ $t("common.cancel") }}</v-btn
        >
        <v-btn
          round
          large
          class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
          @click="saveGroupMenu()"
          :loading="isLoading"
        >
          {{ !model.id ? $t("common.create") : $t("common.save") }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    model: {},
    isLoading: false,
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    menuId() {
      return this.$route.params.menu_id;
    },
    menuTypes() {
      return [
        {
          name: this.$t("group_menu.group_menu_label"),
          slug: "group_menu",
        },
        {
          name: this.$t("group_menu.app_sidemenu_label"),
          slug: "ionic-app-sidemenu",
        },
      ];
    },
  },
  mounted() {
    this.getGroupMenu();
  },
  watch: {
    "$route.params": {
      handler() {
        this.getGroupMenu();
      },
    },
  },
  methods: {
    transformForSave(model) {
      return {
        id: model.id,
        name: model.name,
        slug: model.slug,
        enabled: model.enabled ? 1 : 0,
      };
    },
    transformForEdit(model) {
      return {
        id: model.id,
        name: model.name,
        slug: model.slug,
        enabled: model.enabled,
      };
    },
    getGroupMenu() {
      if (!this.groupId || !this.menuId) return;

      this.isLoading = true;

      const params = [this.groupId, this.menuId];

      this.$api.groupMenus
        .get(...params)
        .then((res) => {
          this.isLoading = false;

          if (!res || !res.data.data.id) return;

          this.model = this.transformForEdit(res.data.data);
        })
        .catch((error) => {
          if (error) {
            this.isLoading = false;
            this.errorMessageShow(error);
          }
        });
    },
    async createGroupMenu() {
      const params = [this.groupId, this.transformForSave(this.model)];

      const response = await this.$api.groupMenus.create(...params);

      this.$store.dispatch("addNotification", {
        message: this.$t("group_menu.create_success_message"),
      });

      this.$router.push(
        {
          name: "group_menu_edit",
          params: {
            menu_id: response.data.data.id,
          },
        },
        () => {},
      );
    },
    async updateGroupMenu() {
      const params = [
        this.groupId,
        this.model.id,
        this.transformForSave(this.model),
      ];

      await this.$api.groupMenus.update(...params);

      this.$store.dispatch("addNotification", {
        message: this.$t("group_menu.update_success_message"),
      });
    },
    async saveGroupMenu() {
      const isValid = await this.$validator.validate();

      if (!isValid) return;

      try {
        this.isLoading = true;

        if (!this.model.id) {
          await this.createGroupMenu();
        } else {
          await this.updateGroupMenu();
        }

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }

      this.$router.push(
        {
          name: "group_menu_list",
        },
        () => {},
      );
    },
  },
};
</script>

<style scoped>
.loading {
  opacity: 0.4;
  transition: all 0.2s;
  pointer-events: none;
}
</style>
